import { endpointWithParams } from '@roche/roche-common';
import {
  getJSON,
  createAuthHeader,
} from '../../../utils/service/service.utils';
import { formatCGMDateString } from '../../../utils/date';
import { CGMClinicalData } from '../../../domains/patient-dashboards/cgm/store/cgm.reducers';
import { ENDPOINTS } from '../../../services/service.constants';
import {
  CGMClinicalDataLoaderImplType,
  CGMClinicalDataTransformImplType,
  CGMClinicalDataServiceImplType,
  CGMClinicalDataResponse,
  IntervalType,
  GlucoseValuesType,
  GlucoseValuesWithIntervalsType,
  GlucoseValueTypesWithIntervals,
} from './cgm-clinical-data.types';

const UNIT_MMOLL = 'MMOLL';
const UNIT_MGDL = 'MGDL';

export const CGMClinicalDataLoaderImpl: CGMClinicalDataLoaderImplType = (
  { fhirId, startDate, endDate, unit },
  accessToken,
) => {
  const endpoint = ENDPOINTS.getFhirRoleClinicalDataCgm;

  const headers = {
    Authorization: createAuthHeader(accessToken),
    rangeStartDate: startDate,
    rangeEndDate: endDate,
    bgUnit: unit === 'mg/dL' ? UNIT_MGDL : UNIT_MMOLL,
  };

  const pathParameters = { fhirId };

  return getJSON(endpointWithParams(endpoint, pathParameters), { headers });
};

const updateDateTimeObject = (
  day: string,
  hour: number,
  macro: number,
  timestamp: string,
  glucoseValue: number,
  interval: any,
  dataObject: CGMClinicalData,
  defaultInterval: any,
) => {
  if (!dataObject.days[day]) dataObject.days[day] = new Array(23);

  if (!dataObject.daysStats[day]) {
    dataObject.daysStats[day] = {
      summationDayValues: 0,
      totalDayMeasurement: 0,
    };
  }

  if (!dataObject.days[day][hour]) {
    dataObject.days[day][hour] = {
      hour: `${hour}:00`,
      totalvalues: 0,
      values: [],
    };
  }

  dataObject.days[day][hour].values.push({
    timestamp,
    glucoseValue,
    macro,
  });

  dataObject.days[day][hour].totalvalues++;
  dataObject.totalMeasurements++;
  dataObject.glucoseSummation += glucoseValue;
  dataObject.daysStats[day].summationDayValues += glucoseValue;
  dataObject.daysStats[day].totalDayMeasurement++;

  // First and Last Measurements per sequence
  const firstTimestamp = interval.intervalFirstMeasurement || timestamp;
  const lastTimestamp = interval.intervalLastMeasurement || timestamp;
  interval.intervalFirstMeasurement =
    timestamp < firstTimestamp ? timestamp : firstTimestamp;
  interval.intervalLastMeasurement =
    timestamp > lastTimestamp ? timestamp : lastTimestamp;

  // First and Last Measurements per day
  if (!interval.intervalDays[day]) {
    interval.intervalDays[day] = {
      intervalFirstMeasurementDay: timestamp,
      intervalLastMeasurementDay: timestamp,
      intervalTotalMeasurementDay: 0,
    };
  }
  interval.intervalDays[day].intervalTotalMeasurementDay++;
  interval.intervalDays[day].interval = defaultInterval;

  if (timestamp < interval.intervalDays[day].intervalFirstMeasurementDay) {
    interval.intervalDays[day].intervalFirstMeasurementDay = timestamp;
  }
  if (timestamp > interval.intervalDays[day].intervalLastMeasurementDay) {
    interval.intervalDays[day].intervalLastMeasurementDay = timestamp;
  }
};

function initializeCGMClinicalData(): CGMClinicalData {
  return {
    rawData: [],
    intervals: [],
    days: {},
    daysStats: {},
    totalMeasurements: 0,
    glucoseSummation: 0,
  };
}

function processData(
  glucoseArray: GlucoseValuesType,
  interval: IntervalType,
  obj: CGMClinicalData,
) {
  glucoseArray?.forEach(({ date, glucose }) => {
    const { day, hour, macro } = formatCGMDateString(date);
    obj.rawData?.push({ date, glucose });

    updateDateTimeObject(
      day,
      hour,
      macro,
      date,
      glucose,
      interval,
      obj,
      interval.interval,
    );
  });
}

const fhirFlowWithOneInterval = (data: CGMClinicalDataResponse) => {
  const obj: CGMClinicalData = initializeCGMClinicalData();

  const length = data.glucoseValues?.length;

  const interval = {
    intervalTotalMeasurements: length,
    interval: data.interval,
    unit: data.intervalUnit,
    intervalFirstMeasurement: '',
    intervalLastMeasurement: '',
    intervalDays: {},
  } as IntervalType;

  if (data.glucoseValues) {
    processData(data.glucoseValues, interval, obj);
  }

  obj.intervals.push(interval as FixMe);

  return obj;
};

const fhirFlowWithMultipleIntervals = (data: CGMClinicalDataResponse) => {
  const obj: CGMClinicalData = initializeCGMClinicalData();
  const groupedByInterval = data.glucoseValues?.reduce<
    GlucoseValuesWithIntervalsType[]
  >((acc, value: GlucoseValueTypesWithIntervals, index: number) => {
    if (index === 0) {
      acc.push([value]);
    } else {
      const lastGroup = acc[acc.length - 1];
      const lastValue = lastGroup[lastGroup.length - 1];
      if (value.interval === lastValue.interval) {
        lastGroup.push(value);
      } else {
        acc.push([value]);
      }
    }
    return acc;
  }, []);

  groupedByInterval?.map((group) => {
    const len = group.length;
    const interval = {
      intervalTotalMeasurements: len,
      interval: group[0].interval,
      unit: group[0].intervalUnit,
      intervalFirstMeasurement: group[0].date,
      intervalLastMeasurement: group[len - 1].date,
      intervalDays: {},
    };

    processData(group, interval, obj);
    obj.intervals.push(interval);
  });

  return obj;
};

const noFhirFlow = (data: CGMClinicalDataResponse) => {
  const obj: CGMClinicalData = initializeCGMClinicalData();

  data.device.forEach((dvce: FixMe) => {
    dvce.data.forEach((dta: FixMe) => {
      dta.sequences.forEach((sqnce: FixMe) => {
        const len = sqnce.data.length;
        const interval = {
          intervalTotalMeasurements: len,
          interval: sqnce.interval,
          unit: sqnce.intervalUnit,
          intervalFirstMeasurement: '',
          intervalLastMeasurement: '',
          intervalDays: {},
        };

        for (let i = 0; i < len; i++) {
          const { timestamp, glucoseValue } = sqnce.data[i];
          const defaultInterval = sqnce.interval;
          // values of object days
          const { day, hour, macro } = formatCGMDateString(timestamp);
          obj.rawData?.push({ date: timestamp, glucose: glucoseValue });
          updateDateTimeObject(
            day,
            hour,
            macro,
            timestamp,
            glucoseValue,
            interval,
            obj,
            defaultInterval,
          );
        }
        obj.intervals.push(interval);
      });
    });
  });
  return obj;
};

export const CGMClinicalDataTransformImpl: CGMClinicalDataTransformImplType = (
  data,
) => {
  if (data.hasRoleFhir && data.interval) {
    return fhirFlowWithOneInterval(data);
  } else if (data.hasRoleFhir && !data.interval) {
    return fhirFlowWithMultipleIntervals(data);
  } else {
    return noFhirFlow(data);
  }
};

export const CGMClinicalDataServiceImpl: CGMClinicalDataServiceImplType =
  (
    load: CGMClinicalDataLoaderImplType,
    transform: CGMClinicalDataTransformImplType,
  ) =>
  (query, accessToken, gigyaToken) =>
    load(query, accessToken, gigyaToken).then((data) =>
      transform({ ...data, hasRoleFhir: query.hasRoleFhir }),
    );
