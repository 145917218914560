import React, { useState } from 'react';
import styles from './styles.module.css';
import { JelloIcon } from 'src/components/jello-icon/jello-icon.component';
import { colors } from 'src/core/styles/colors';
import { MenuOptionComponent } from './menu-option/menu-option-jello-component';
import { t } from 'i18next';

import {
  JELLO_ICON_NAMES,
  JELLO_ICON_SIZES,
} from 'src/app/app.jello.constants';
import { routes } from 'src/domains/routes';
import {
  SIDEBAR_MENU,
  TOGGLER,
  STRIP_MANAGEMENT,
  getItems,
  ADMIN_PATH_KEY,
  PICK_UP_PATH_KEY,
  HOME_DELIVERY_PATH_KEY,
} from './constants';
import { testId } from '@roche/roche-common';
import { matchPath } from 'react-router-dom';
import { ADMINISTRATIVE } from 'src/core/user/user.constants';

export const SidebarmenuJelloComponent = (props) => {
  const [expanded, setExpanded] = useState(false);
  const {
    disabled,
    hidden,
    patientId,
    location,
    onClearBgDateRange,
    onClearCgmDateRange,
    goTo,
    profile,
    isPatientStrip,
  } = props;

  const { sidebarMenu, displayed, btn, btnExpanded, btnContainer } = styles;
  const ITEMS = getItems(routes);

  const checkTipeStrip =
    profile === ADMINISTRATIVE
      ? ADMIN_PATH_KEY
      : isPatientStrip
      ? PICK_UP_PATH_KEY
      : HOME_DELIVERY_PATH_KEY;

  const checkSelected = (key, _path) => {
    const selected = ITEMS[key].activePaths.find((p) => {
      return !!matchPath(location?.pathname, {
        path: p,
        exact: true,
      });
    });

    return !!selected;
  };

  const pageIconName = expanded
    ? JELLO_ICON_NAMES.PAGE_FIRST
    : JELLO_ICON_NAMES.PAGE_LAST;

  return (
    <div
      className={`${sidebarMenu} ${expanded ? displayed : ''}`}
      {...testId(SIDEBAR_MENU, '')}
    >
      {Object.entries(ITEMS).map(([key, { iconName, label, path }]) => {
        const _path =
          key === STRIP_MANAGEMENT ? `${path[checkTipeStrip] || path}` : path;

        return (
          <MenuOptionComponent
            id={key}
            key={key}
            expanded={expanded}
            selected={checkSelected(key, _path)}
            iconName={iconName}
            label={t(label)}
            path={_path.replace(':id', patientId)}
            disabled={disabled()[key]}
            hidden={hidden()[key]}
            onClearBgDateRange={onClearBgDateRange}
            onClearCgmDateRange={onClearCgmDateRange}
            goTo={goTo}
          />
        );
      })}

      <div className={btnContainer}>
        <>
          <hr className={styles.spacer} />
          <jello-tooltip
            delay="300"
            label={t(`sidebarmenu.${expanded ? 'collapse' : 'expand'}`)}
            position="right"
            style={{ display: 'block', float: 'right' }}
          >
            <button
              onClick={() => setExpanded(!expanded)}
              className={`${btn} ${expanded ? btnExpanded : ''}`}
              {...testId(SIDEBAR_MENU, TOGGLER)}
            >
              <JelloIcon
                iconName={pageIconName}
                size={JELLO_ICON_SIZES.S}
                // @ts-ignore
                styles={{ color: colors.brandBlue, padding: '8px' }}
              />
            </button>
          </jello-tooltip>
        </>
      </div>
    </div>
  );
};
