import { FORMAT, LAST_ACTIVE, TABS, BEFORE_UNLOAD } from '../../constants';
import moment from 'moment';
import { customDatadogLog } from 'src/app/logger/utils';
import { LOGS } from 'src/app/logger/constants';
import { useDetectReloadActionFromBrowser } from 'src/hooks';

export const handlesActivity = (props) => {
  const {
    reset,
    pause,
    isGettingToken,
    expiration: _e,
    _remaining,
    onShowExpiringModal,
    onRefreshToken,
    isLeader = () => false,
  } = props;

  if (isLeader()) {
    const ONE_SECOND_IN_MILLIS = 1000;
    const m = 2;
    const a = 120;
    const b = 90;
    const c = 60;
    const d = 30;
    const e = 10;
    const now = moment();
    const expiration = moment(_e, FORMAT);
    const closeToExpire = now.isAfter(expiration.clone().subtract(m, 'm'));
    const userIsInactive = Math.round(_remaining / ONE_SECOND_IN_MILLIS) === 0;
    const times = [a, b, c, d, e].includes(expiration.diff(now, 's'));
    const refresh = (closeToExpire && !isGettingToken) || times;

    if (refresh) {
      customDatadogLog('session', LOGS.tokenRefreshment);
      onRefreshToken();
    }

    if (userIsInactive) {
      customDatadogLog('session', LOGS.showExpiringModal);
      reset();
      pause();
      onShowExpiringModal();
    }
  }
};

/* istanbul ignore next */ export const handlesReload = () => {
  const detectReload = useDetectReloadActionFromBrowser();
  if (detectReload) {
    localStorage.setItem(LAST_ACTIVE, `${moment().format(FORMAT)}`);
    customDatadogLog('session', LOGS.pageReload);
  }
};

export const handlesLastActive = (onSignOutAction, tabId, isSignedOut) => {
  localStorage.setItem(
    TABS,
    `${localStorage.getItem(TABS)}`
      .replace('undefined', '')
      .replace('null', '') + tabId,
  );
  const secs = 30;
  const la = localStorage.getItem(LAST_ACTIVE);
  const tooMuchTime = la && moment().diff(moment(la, FORMAT), 's') >= secs;

  const onlyOneTab =
    `${localStorage.getItem(TABS)}`
      .replace('undefined', '')
      .replace('null', '') === tabId;

  if (onlyOneTab && tooMuchTime && !isSignedOut) {
    customDatadogLog('session', LOGS.thirtySecs);
    onSignOutAction();
  }
};

export const onClearAllLocalStorage = () => {
  localStorage.removeItem(LAST_ACTIVE);
  localStorage.removeItem(TABS);
};

export const unloadFunction = (e, tabId) => {
  localStorage.setItem(LAST_ACTIVE, `${moment().format(FORMAT)}`);
  localStorage.setItem(
    TABS,
    /* istanbul ignore next */
    `${localStorage.getItem(TABS)?.replace(tabId, '') || ''}`,
  );
  return e;
};

export const handlesOnBeforeUnload = (tabId) => {
  /* istanbul ignore next */ window.addEventListener(BEFORE_UNLOAD, (e) => {
    unloadFunction(e, tabId);
  });
  return () => {
    /* istanbul ignore next */ window.removeEventListener(
      BEFORE_UNLOAD,
      (e) => {
        unloadFunction(e, tabId);
      },
    );
  };
};
