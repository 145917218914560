import React from 'react';
import styles from './styles.module.css';
import { JelloIcon } from 'src/components/jello-icon/jello-icon.component';

import { JELLO_ICON_SIZES } from 'src/app/app.jello.constants';
import { SIDEBAR_MENU, MENU_OPTION } from '../constants';
import { testId } from '@roche/roche-common';
import { Link } from 'react-router-dom';

type MenuOption = {
  id: string;
  expanded: boolean;
  selected: boolean;
  iconName: string;
  label: string;
  disabled?: boolean;
  hidden?: boolean;
  path: string;
  onClearCgmDateRange?: any;
  onClearBgDateRange?: any;
  goTo?: any;
};

export const MenuOptionComponent = (props: MenuOption) => {
  const {
    expanded,
    selected,
    iconName,
    label,
    disabled,
    hidden,
    path,
    id,
    onClearCgmDateRange,
    onClearBgDateRange,
    goTo,
  } = props;
  const getChipClasses = () =>
    `${styles.chip} ${selected ? styles.selected : ''} ${
      disabled ? styles.disabled : ''
    }`;
  return (
    <Link
      to={path}
      className={hidden ? styles.btnHidden : styles.btn}
      onClick={(e) => {
        e.preventDefault();
        if (disabled) {
          return;
        }
        if (['cgm', 'bgm'].indexOf(id) > -1) {
          onClearCgmDateRange();
          onClearBgDateRange();
        }
        goTo(path);
      }}
      {...testId(SIDEBAR_MENU, `${MENU_OPTION}-${id}`)}
    >
      {!expanded && (
        <jello-tooltip
          style={{ display: 'block' }}
          delay="300"
          label={label}
          position="right"
          {...{ disabled: expanded ? '' : null }}
        >
          <div className={getChipClasses()}>
            <JelloIcon
              className={styles.icon}
              iconName={iconName}
              size={JELLO_ICON_SIZES.S}
              // @ts-ignore
              styles={{ float: 'left' }}
              disabled={disabled}
            />
          </div>
        </jello-tooltip>
      )}
      {expanded && (
        <div className={getChipClasses()}>
          <JelloIcon
            className={styles.icon}
            iconName={iconName}
            size={JELLO_ICON_SIZES.S}
            // @ts-ignore
            styles={{ float: 'left' }}
            disabled={disabled}
          />
          <jello-text
            className={styles.label}
            style={{ float: 'left', margin: '2px 8px' }}
          >
            {label}
          </jello-text>
        </div>
      )}
    </Link>
  );
};
