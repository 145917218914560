import {
  DiabetesType,
  TherapyType,
} from '../../../services/patient/patient.types';
import { PatientGender } from '../../../core/patient/patient.types';

export const MODE_EDIT = 'EDIT';
export const MODE_CREATE = 'CREATE';
export const INVITE_PATIENT_INFO_PATH = 'savePatientWithFhir.invitation';
export const RDAC_INVITATION = {
  promptText: 'prompt',
  detailText: 'detail',
  actionText: 'action',
  inviteSuccess: 'inviteToastSuccessTitle',
};
export const CREATE_PATIENT_PATH = 'createPatientWithPlatform';
export const JELLO_ALERT_CLOSE_EVENT = 'jello-alert-close-action';
export const EDIT_SAVE_BUTTON = `${CREATE_PATIENT_PATH}.saveButton`;
export const EDIT_CANCEL_BUTTON = `${CREATE_PATIENT_PATH}.cancelButton`;
export const SHARING_CODE_PATH = `${CREATE_PATIENT_PATH}.pairingCode`;
export const SHARING_CODE_EDIT_PATH = 'editPatientWithPlatform.pairingCode';
export const SHARING_CODE_ERROR_MODAL_PATH = `${CREATE_PATIENT_PATH}.errorModal.pairingCode`;
export const INVITE_PATIENT_MODAL_PATH = 'savePatientWithFhir.inviteModal';
export const REQUIRED_INFO_PATH = `${CREATE_PATIENT_PATH}.requiredInfo`;
export const OPTIONAL_INFO_PATH = `${CREATE_PATIENT_PATH}.optionalInfo`;
export const OPTIONAL_INFO_TITLES = `${OPTIONAL_INFO_PATH}.title`;
export const OPTIONAL_INFO_LABELS = `${OPTIONAL_INFO_PATH}.label`;
export const OPTIONAL_INFO_PLACEHOLDERS = `${OPTIONAL_INFO_PATH}.placeholder`;
export const OPTIONAL_INFO_OPTIONS = `${OPTIONAL_INFO_PATH}.options`;
export const REQUIRED_INFO_VALIDATORS_PATH = 'profile.validations';
export const VALIDATORS_PATH = 'createPatient.patientInfoValidation';
export const FORM_VALIDATORS_PATH = 'professional.validations';
export const ACCOUNT_CONFIG_PATH = `${CREATE_PATIENT_PATH}.accountConfig`;
export const ACCOUNT_CONFIG_TITLES = `${ACCOUNT_CONFIG_PATH}.title`;
export const ACCOUNT_CONFIG_LABELS = `${ACCOUNT_CONFIG_PATH}.label`;
export const ACCOUNT_CONFIG_HINTS = `${ACCOUNT_CONFIG_PATH}.hint`;
export const ACCOUNT_CONFIG_PLACEHOLDERS = `${ACCOUNT_CONFIG_PATH}.placeholder`;
export const ACCOUNT_CONFIG_OPTIONS = `${ACCOUNT_CONFIG_PATH}.options`;
export const CREATE_ERROR_MODAL_PATH = `${CREATE_PATIENT_PATH}.errorModal.creation`;
export const BIRTHDATE_ALERT_PATH = `${CREATE_PATIENT_PATH}.alert.birthdateHasError`;
export const EDIT_SAVE_ERROR_MODAL_PATH = 'savePatientWithFhir.errorModal';
export const EDIT_ERROR_MODAL_PATH = 'editPatientWithFhir.errorModal.edition';
export const CORRELATE_PATIENT_ERROR_MODAL_PATH =
  'savePatientWithFhir.errorModal.correlation';
export const CORRELATE_PATIENT_ERROR_CONFLICT_MODAL_PATH = `${EDIT_SAVE_ERROR_MODAL_PATH}.correlation.conflict`;
export const INVITE_PATIENT_ERROR = `${EDIT_SAVE_ERROR_MODAL_PATH}.invitation`;
export const SUCCESS_MODAL_PATH = `${CREATE_PATIENT_PATH}.successModal`;
export const EDIT_MODAL_PATH = 'editPatientWithPlatform.successModal';
export const SUCCESS_MODAL_PATH_EDIT =
  'editPatientWithPlatform.successModal.buttons.StartSharing';
export const PREGNANCY_BASE_KEY = 'createPatient.healthInfoLabels';

export const COSTUMER_SUPPORT_URL =
  'https://support.rochediabetes.com/careplatform/en/contact-us';

export const COUNTRY_ID = 1;

export const FORM_MODAL_TYPES = {
  SHARING_DATA_COMPARISON: 'sharing-data-comparison',
  SHARING_CODE_SUCCESS: 'success',
  ERROR: 'error',
  INVITATION: 'invitation',
  CANCEL_EDIT: 'cancel-edit',
  SHARING_CODE_INFORMATION: 'sharing-code-info',
};

export const FORM_ERROR_MODAL_TYPES = {
  SHARING_CODE_ERROR_CREATE: 'SHARING_CODE_ERROR_CREATE',
  SHARING_CODE_ERROR_EDIT: 'SHARING_CODE_ERROR_EDIT',
  SHARING_CODE_CONFLICT_ERROR: 'SHARING_CODE_CONFLICT_ERROR',
  SHARING_CODE_CORRELATE_ERROR: 'SHARING_CODE_CORRELATE_ERROR',
  CREATE_ERROR: 'CREATE_ERROR',
  EDIT_ERROR: 'EDIT_ERROR',
  INVITATION_ERROR: 'INVITATION_ERROR',
};

export const SHARING_CODE_ERRORS = `${SHARING_CODE_PATH}.errors`;
export const SHARING_CODE_HELP_MESSAGE = `${SHARING_CODE_PATH}.helpMessage`;

export const ERROR_429 = 429;
export const ERROR_409 = 409;
export const ERROR_404 = 404;
export const DEBOUNCE_TIME = 500;
export const SHARING_CODE_LENGTH = 5;
export const ICON_SIZE = 96;
export const ICON_VIEWBOX = '0 0 96 96';
export const ICON_FILL_NONE = 'none';

export const SHARING_CODE_ICON =
  'https://public-assets-devus.rochedcplatform-sandbox.com/illustrations/svg/generic-data-sharing.svg';

export const CREATION_TEXTS = {
  DESCRIPTION_TITLE: `${SHARING_CODE_PATH}.title`,
  DESCRIPTION_EXPLANATION: `${SHARING_CODE_PATH}.explanation`,
  VALIDATE_BUTTON: `${SHARING_CODE_PATH}.validateButton`,
};

export const EDITING_TEXTS = {
  DESCRIPTION_TITLE: `${SHARING_CODE_EDIT_PATH}.title`,
  DESCRIPTION_EXPLANATION: `${SHARING_CODE_EDIT_PATH}.explanation`,
  VALIDATE_BUTTON: `${SHARING_CODE_PATH}.connectButton`,
};

export const INPUT_DATE_FORMAT = 'yyyy-MM-dd';
export const INPUT_MIN_DATE = '1900-01-01';

export const PAIRING_CODE = 'pairing-code';
export const REQUIRED_INFO = 'required-info';
export const EMAIL_INFO = 'email-info';
export const SELECT_INFO = 'select-info';
export const OPTIONAL_INFO = 'optional-info';
export const CHECKBOX = 'checkbox';
export const ADDRESS_INFO = 'address-info';
export const ATTEMPTS = 'attempts';
export const IS_VALID = 'is-valid';
export const IS_FILLED = 'is-filled';
export const IS_DIRTY = 'is-dirty';
export const IS_OTHER_DEPARTMENT = 'is-other-department';
export const VALUE = 'value';

export const INPUT_NAMES = {
  NAME: 'name',
  LASTNAME: 'lastname',
  BIRTHDATE: 'birthdate',
  EMAIL_CHECK: 'email-check',
  EMAIL: 'email',
  EMAIL_WITH_CHECK: 'email_with_check',
  HEALTHCARE_ID: 'healthcare-id',
  DIABETES_TYPE: 'diabetes-type',
  THERAPY: 'therapy',
  DIAGNOSIS_DATE: 'diagnosis-date',
  ASSOCIATED_PROFESSIONAL: 'associated-professional',
  LANGUAGE: 'language',
  SEX: 'sex',
  PHONE_NUMBER: 'phone-number',
  RPM_CHECK: 'rpm-check',
  OPEN_CONNECTIVITY_CHECK: 'open-connectivity-check',
  STRIP_CHECK: 'strip-check',
  STRIP_OPTION: 'strip-option',
  ADDRESS: 'address-line',
  CITY: 'city',
  POSTCODE: 'postcode',
  PROVINCE: 'province',
  COUNTRY: 'country',
  SHARING_CODE: 'sharing-code',
  COUNTER: 'counter',
  PREGNANT: 'pregnancy',
  DUE_DELIVERY_DATE: 'due-delivery-date',
};

export const HOME_DELIVERY_CONFIG = 'STANDARD_WITH_HOME_DELIVERY';
export const PICKUP_AT_HOME_CONFIG = 'STANDARD_WITH_STRIP_MANAGEMENT';

export const RPM_PROGRAM = 'RPM_PROGRAM';
export const OPEN_CONNECTIVITY = 'OPEN_CONNECTIVITY_DATA_DOWN';

export const SELECT_OPTION_DEFAULT = [
  {
    label: `${OPTIONAL_INFO_OPTIONS}.selectOne`,
    value: '',
  },
];

export const STRIP_MGNT_OPTIONS_VALUES = {
  HOME_DELIVERY: HOME_DELIVERY_CONFIG,
  STRIP_MANAGEMENT: PICKUP_AT_HOME_CONFIG,
};

export const DIABETES_OPTIONS_PLATFORM = [
  ...SELECT_OPTION_DEFAULT,
  {
    label: `${OPTIONAL_INFO_OPTIONS}.diabetesType.one`,
    value: DiabetesType.DIABETES_TYPE1,
  },
  {
    label: `${OPTIONAL_INFO_OPTIONS}.diabetesType.two`,
    value: DiabetesType.DIABETES_TYPE2,
  },
  {
    label: `${OPTIONAL_INFO_OPTIONS}.diabetesType.three`,
    value: DiabetesType.DIABETES_GESTATIONAL,
  },
  {
    label: `${OPTIONAL_INFO_OPTIONS}.diabetesType.four`,
    value: DiabetesType.DIABETES_MODY,
  },
  {
    label: `${OPTIONAL_INFO_OPTIONS}.diabetesType.five`,
    value: DiabetesType.DIABETES_LADA,
  },
  {
    label: `${OPTIONAL_INFO_OPTIONS}.diabetesType.six`,
    value: DiabetesType.DIABETES_PREDIABETES,
  },
  {
    label: `${OPTIONAL_INFO_OPTIONS}.diabetesType.seven`,
    value: DiabetesType.DIABETES_UNKNOWN,
  },
];

export const THERAPY_OPTIONS_PLATFORM = [
  ...SELECT_OPTION_DEFAULT,
  {
    label: `${OPTIONAL_INFO_OPTIONS}.therapy.one`,
    value: TherapyType.MDI,
  },
  {
    label: `${OPTIONAL_INFO_OPTIONS}.therapy.two`,
    value: TherapyType.CSII,
  },
  {
    label: `${OPTIONAL_INFO_OPTIONS}.therapy.three`,
    value: TherapyType.BASAL,
  },
  {
    label: `${OPTIONAL_INFO_OPTIONS}.therapy.four`,
    value: TherapyType.OAD,
  },
  {
    label: `${OPTIONAL_INFO_OPTIONS}.therapy.five`,
    value: TherapyType.NII,
  },
  {
    label: `${OPTIONAL_INFO_OPTIONS}.therapy.six`,
    value: TherapyType.BOT,
  },
  {
    label: `${OPTIONAL_INFO_OPTIONS}.therapy.seven`,
    value: TherapyType.FRC,
  },
  {
    label: `${OPTIONAL_INFO_OPTIONS}.therapy.eight`,
    value: TherapyType.AID,
  },
  {
    label: `${OPTIONAL_INFO_OPTIONS}.therapy.nine`,
    value: TherapyType.LIFESTYLE,
  },
];

export const GENDER_OPTIONS_PLATFORM = [
  {
    label: `${OPTIONAL_INFO_OPTIONS}.selectOne`,
    value: '',
  },
  {
    label: `${OPTIONAL_INFO_OPTIONS}.gender.male`,
    value: PatientGender.MALE,
  },
  {
    label: `${OPTIONAL_INFO_OPTIONS}.gender.female`,
    value: PatientGender.FEMALE,
  },
  {
    label: `${OPTIONAL_INFO_OPTIONS}.gender.unspecified`,
    value: PatientGender.UNSPECIFIED,
  },
];

export const CREATE_FORM_INPUT_BASE = {
  [REQUIRED_INFO]: {
    [INPUT_NAMES.NAME]: { [IS_VALID]: false, [IS_FILLED]: false },
    [INPUT_NAMES.BIRTHDATE]: { [IS_VALID]: false, [IS_FILLED]: false },
    [INPUT_NAMES.LASTNAME]: { [IS_VALID]: false, [IS_FILLED]: false },
  },
  [EMAIL_INFO]: {
    [INPUT_NAMES.EMAIL_CHECK]: true,
    [INPUT_NAMES.EMAIL_WITH_CHECK]: false,
    [INPUT_NAMES.EMAIL]: false,
  },
  [INPUT_NAMES.ASSOCIATED_PROFESSIONAL]: { [IS_FILLED]: false, [VALUE]: [] },
  [SELECT_INFO]: {
    [INPUT_NAMES.DIABETES_TYPE]: {
      [IS_VALID]: false,
      [IS_FILLED]: false,
      [VALUE]: '',
    },
    [INPUT_NAMES.THERAPY]: {
      [IS_VALID]: false,
      [IS_FILLED]: false,
      [VALUE]: '',
    },
    [INPUT_NAMES.LANGUAGE]: {
      [IS_VALID]: false,
      [IS_FILLED]: false,
      [VALUE]: null,
    },
    [INPUT_NAMES.SEX]: {
      [IS_VALID]: false,
      [IS_FILLED]: false,
      [VALUE]: PatientGender.SELECTOPTION,
    },
    [INPUT_NAMES.STRIP_OPTION]: {
      [IS_VALID]: false,
      [IS_FILLED]: false,
      [VALUE]: null,
    },
    [INPUT_NAMES.COUNTRY]: {
      [IS_VALID]: false,
      [IS_FILLED]: false,
      [VALUE]: null,
    },
  },
  [CHECKBOX]: {
    [INPUT_NAMES.RPM_CHECK]: false,
    [INPUT_NAMES.OPEN_CONNECTIVITY_CHECK]: false,
    [INPUT_NAMES.STRIP_CHECK]: false,
  },
  [OPTIONAL_INFO]: {
    [INPUT_NAMES.HEALTHCARE_ID]: { [IS_VALID]: false, [IS_FILLED]: false },
    [INPUT_NAMES.DIAGNOSIS_DATE]: { [IS_VALID]: false, [IS_FILLED]: false },
    [INPUT_NAMES.PHONE_NUMBER]: { [IS_VALID]: false, [IS_FILLED]: false },
    [INPUT_NAMES.DUE_DELIVERY_DATE]: { [IS_VALID]: false, [IS_FILLED]: false },
    [INPUT_NAMES.PREGNANT]: {
      [IS_VALID]: false,
      [IS_FILLED]: false,
      [VALUE]: false,
    },
  },
  [ADDRESS_INFO]: {
    [INPUT_NAMES.ADDRESS]: { [IS_VALID]: false, [IS_FILLED]: false },
    [INPUT_NAMES.CITY]: { [IS_VALID]: false, [IS_FILLED]: false },
    [INPUT_NAMES.POSTCODE]: { [IS_VALID]: false, [IS_FILLED]: false },
    [INPUT_NAMES.PROVINCE]: { [IS_VALID]: false, [IS_FILLED]: false },
  },
  [ATTEMPTS]: {
    [INPUT_NAMES.COUNTER]: 1,
  },
};

export const EDIT_FORM_INPUT_BASE = {
  [REQUIRED_INFO]: {
    [INPUT_NAMES.NAME]: {
      [IS_VALID]: false,
      [IS_FILLED]: false,
      [IS_DIRTY]: false,
    },
    [INPUT_NAMES.BIRTHDATE]: {
      [IS_VALID]: false,
      [IS_FILLED]: false,
      [IS_DIRTY]: false,
    },
    [INPUT_NAMES.LASTNAME]: {
      [IS_VALID]: false,
      [IS_FILLED]: false,
      [IS_DIRTY]: false,
    },
  },
  [INPUT_NAMES.ASSOCIATED_PROFESSIONAL]: {
    [IS_FILLED]: false,
    [IS_DIRTY]: false,
    [IS_OTHER_DEPARTMENT]: false,
    [VALUE]: [],
  },
  [INPUT_NAMES.SHARING_CODE]: {
    [IS_DIRTY]: false,
  },
  [SELECT_INFO]: {
    [INPUT_NAMES.DIABETES_TYPE]: {
      [IS_VALID]: false,
      [IS_FILLED]: false,
      [VALUE]: '',
      [IS_DIRTY]: false,
    },
    [INPUT_NAMES.THERAPY]: {
      [IS_VALID]: false,
      [IS_FILLED]: false,
      [VALUE]: '',
      [IS_DIRTY]: false,
    },
    [INPUT_NAMES.LANGUAGE]: {
      [IS_VALID]: false,
      [IS_FILLED]: false,
      [VALUE]: null,
      [IS_DIRTY]: false,
    },
    [INPUT_NAMES.SEX]: {
      [IS_VALID]: false,
      [IS_FILLED]: false,
      [VALUE]: '',
      [IS_DIRTY]: false,
    },
    [INPUT_NAMES.STRIP_OPTION]: {
      [IS_VALID]: false,
      [IS_FILLED]: false,
      [VALUE]: null,
      [IS_DIRTY]: false,
    },
    [INPUT_NAMES.COUNTRY]: {
      [IS_VALID]: false,
      [IS_FILLED]: false,
      [VALUE]: null,
      [IS_DIRTY]: false,
    },
  },
  [CHECKBOX]: {
    [INPUT_NAMES.RPM_CHECK]: { [VALUE]: false, [IS_DIRTY]: false },
    [INPUT_NAMES.OPEN_CONNECTIVITY_CHECK]: {
      [VALUE]: false,
      [IS_DIRTY]: false,
    },
    [INPUT_NAMES.STRIP_CHECK]: { [VALUE]: false, [IS_DIRTY]: false },
  },
  [OPTIONAL_INFO]: {
    [INPUT_NAMES.EMAIL]: {
      [IS_VALID]: false,
      [IS_FILLED]: false,
      [IS_DIRTY]: false,
    },
    [INPUT_NAMES.HEALTHCARE_ID]: {
      [IS_VALID]: false,
      [IS_FILLED]: false,
      [IS_DIRTY]: false,
    },
    [INPUT_NAMES.DIAGNOSIS_DATE]: {
      [IS_VALID]: false,
      [IS_FILLED]: false,
      [IS_DIRTY]: false,
    },
    [INPUT_NAMES.PHONE_NUMBER]: {
      [IS_VALID]: false,
      [IS_FILLED]: false,
      [IS_DIRTY]: false,
    },
    [INPUT_NAMES.DUE_DELIVERY_DATE]: {
      [IS_VALID]: false,
      [IS_FILLED]: false,
      [IS_DIRTY]: false,
    },
    [INPUT_NAMES.PREGNANT]: {
      [IS_VALID]: false,
      [IS_FILLED]: false,
      [VALUE]: false,
      [IS_DIRTY]: false,
    },
  },
  [ADDRESS_INFO]: {
    [INPUT_NAMES.ADDRESS]: {
      [IS_VALID]: false,
      [IS_FILLED]: false,
      [IS_DIRTY]: false,
    },
    [INPUT_NAMES.CITY]: {
      [IS_VALID]: false,
      [IS_FILLED]: false,
      [IS_DIRTY]: false,
    },
    [INPUT_NAMES.POSTCODE]: {
      [IS_VALID]: false,
      [IS_FILLED]: false,
      [IS_DIRTY]: false,
    },
    [INPUT_NAMES.PROVINCE]: {
      [IS_VALID]: false,
      [IS_FILLED]: false,
      [IS_DIRTY]: false,
    },
  },
  [ATTEMPTS]: {
    [INPUT_NAMES.COUNTER]: 1,
  },
};

export const TEST_ID_BASE_CREATE = 'jello-create-form';
export const TEST_ID_BASE_EDIT = 'jello-edit-form';
export const TEST_ID_BASE_FORM = 'jello-patient-form';

export const TEST = {
  INPUT: 'input',
  CHECKBOX: 'checkbox',
  BUTTON: 'button',
  SELECT: 'select',
  EDIT: 'edit',
  REQ_INFO_CONTINUE: 'required-info-continue',
  SUBMIT: 'submit',
  CANCEL: 'cancel',
  SEARCH_BOX: 'search-box',
  BOX: 'box',
  CHIP: 'chip',
  TEXT: 'text',
};

export const ERROR_MODAL_TEXT = {
  // CREATE ERRORS
  [FORM_ERROR_MODAL_TYPES.SHARING_CODE_ERROR_CREATE]: {
    title: `${SHARING_CODE_ERROR_MODAL_PATH}.title`,
    text1: `${SHARING_CODE_ERROR_MODAL_PATH}.text1`,
    text2: `${SHARING_CODE_ERROR_MODAL_PATH}.text2`,
    hasLink: false,
  },
  [FORM_ERROR_MODAL_TYPES.CREATE_ERROR]: {
    title: `${CREATE_ERROR_MODAL_PATH}.title`,
    text1: `${CREATE_ERROR_MODAL_PATH}.text1`,
    text2: `${CREATE_ERROR_MODAL_PATH}.text2`,
    hasLink: false,
  },
  // EDIT ERRORS
  [FORM_ERROR_MODAL_TYPES.SHARING_CODE_ERROR_EDIT]: {
    title: `${SHARING_CODE_ERROR_MODAL_PATH}.notFound.title`,
    text1: `${SHARING_CODE_ERROR_MODAL_PATH}.text1`,
    text2: `${SHARING_CODE_ERROR_MODAL_PATH}.text2`,
    hasLink: false,
  },
  [FORM_ERROR_MODAL_TYPES.SHARING_CODE_CORRELATE_ERROR]: {
    title: `${CORRELATE_PATIENT_ERROR_MODAL_PATH}.title`,
    text1: `${CORRELATE_PATIENT_ERROR_MODAL_PATH}.text1`,
    text2: `${CORRELATE_PATIENT_ERROR_MODAL_PATH}.text2`,
    hasLink: false,
  },
  [FORM_ERROR_MODAL_TYPES.SHARING_CODE_CONFLICT_ERROR]: {
    title: `${CORRELATE_PATIENT_ERROR_CONFLICT_MODAL_PATH}.title`,
    text1: `${CORRELATE_PATIENT_ERROR_CONFLICT_MODAL_PATH}.text1`,
    text2: '',
    hasLink: true,
  },
  [FORM_ERROR_MODAL_TYPES.EDIT_ERROR]: {
    title: `${EDIT_ERROR_MODAL_PATH}.title`,
    text1: `${EDIT_ERROR_MODAL_PATH}.text1`,
    text2: `${EDIT_ERROR_MODAL_PATH}.text2`,
    hasLink: false,
  },
  [FORM_ERROR_MODAL_TYPES.INVITATION_ERROR]: {
    title: `${INVITE_PATIENT_ERROR}.title`,
    text1: `${SHARING_CODE_ERROR_MODAL_PATH}.text1`,
    text2: `${SHARING_CODE_ERROR_MODAL_PATH}.text2`,
    hasLink: false,
  },
};

// COUNTLY

export const DATA_SHARING_EVENTS_KEYS = 'Data Sharing';

export const DATA_SHARING_SEGMENT_KEYS = {
  CONFIRM_MODAL_EDIT: 'Confirm to start sharing modal',
};

export const DATA_SHARING_SEGMENT_VALUES = {
  DATA_SHARING_INICIATED_EDIT: 'Initiated Confirm to start sharing',
  DATA_SHARING_CONFIRMATION_EDIT: 'Completed Confirm to start sharing',
  CANCEL_SAME_EMAIL: 'Cancel with same email',
  CANCEL_DIFFERENT_EMAIL: 'Cancel with  different email',
};

export const CREATE_PATIENT_EVENTS_KEYS = {
  CREATE_PATIENT_INITIATED: 'PatientCreation opened',
  CREATE_PATIENT_STEP_1_COMPLETED: 'PatientCreation clicked continueButton',
  CREATE_PATIENT_COMPLETED: 'PatientCreation clicked createPatientButton',
};

export const CREATE_PATIENT_SEGMENT_KEYS = {
  ACCESS: 'Access',
  INVITE: 'Invite',
  EMAIL: 'Email',
  HEALTHCARE_ID: 'HealthcareID',
  DIABETES_TYPE: 'Diabetes type',
  THERAPY: 'Therapy',
  DIAGNOSIS_DATE: 'Diagnosis date',
  LANGUAGE: 'Language',
  SEX_ASSIGN_TO_BIRTH: 'Sex assigned at birth',
  PHONE: 'Phone number',
  RPM_PROGRAM: 'RPM Program',
  OPEN_CONNECTIVITY: 'Open Connectivity',
  PROFILE_TYPE: 'Profile type',
  PREGNANCY: 'Pregnant',
};

export const CONNECT_DATA_SHARING_EVENTS_KEYS = {
  VALID_CODE: 'DataSharing input validCode',
  INVALID_CODE: 'DataSharing viewed invalidCodeError',
  PATIENT_CONNECTION_COMPLETED: 'DataSharing completed',
  BLOCKED: 'DataSharing viewed blockedError',
  ERROR_IN_GET_PATIENT_INFO: 'DataSharing viewed patientInformationError',
  ERROR_DUPLICATED_CONNECTION: 'DataSharing viewed invalidConnectionError',
  ERROR_CONFLICT: 'DataSharing viewed errorConflictDetected',
};

export const CONNECT_DATA_SHARING_SEGMENT_KEYS = {
  FORM: 'Form',
  ATTEMPTS: 'Attempts',
};

export const ATTEMPTS_LABELS = {
  ONE: 1,
  TWO: 2,
  THREE: 3,
  FIRST: 'First Attempt',
  SECOND: 'Second Attempt',
  THIRD: 'Third Attempt',
};

export const PATIENT_CREATION = 'Patient creation';
export const PATIENT_EDITION = 'Patient edition';
export const NA = 'N/A';
export const BASIC = 'Basic';
export const HOME_DELIVERY = 'Home Delivery';
export const PICK_UP_CENTER = 'Pick up at center';

export const INVITE_OPEN_EVENT_KEY = 'DataSharing opened inviteModal';
export const INVITE_CLICKED_CANCEL_EVENT_KEY =
  'DataSharing clicked cancelInviteButton';
export const INVITE_CLICKED_SEND_EVENT_KEY =
  'DataSharing clicked sendInviteButton';

export const INVITE_CLICKED_SEND_SEGMENT = 'Success';

export const INVITE_CLICKED_SEND_SEGMENT_VALUES = {
  TRUE: 'True',
  FALSE: 'False',
};
