import React, { FunctionComponent } from 'react';
import {
  JELLO_BUTTON_VARIANTS,
  JELLO_BUTTON_SIZES,
} from 'src/app/app.jello.constants';
import { t } from 'i18next';
import { RenderIf } from 'src/utils/render-if';
import { DataSharingBtnProps, Status } from '../types';
import { routes } from 'src/domains/routes';
import { getStatus, getStatusProps } from '../utils';
import { BTN_ID } from '../constants';
import styles from './styles.module.css';

const { S, L } = JELLO_BUTTON_SIZES;

const isNullOrUndefined = (value) => {
  return value === undefined || value === null;
};

export const DataSharingStatusBtnComponent: FunctionComponent<
  DataSharingBtnProps
> = ({
  consentState,
  goTo,
  patient,
  isInterventionalStudyPatient,
  small,
  disabled,
}) => {
  const { status } = { ...consentState };
  const { id } = { ...patient };
  const buttonProps = {
    ...getStatusProps(
      BTN_ID,
      getStatus(status) as Status,
      isInterventionalStudyPatient,
    ),
  };

  const size = small === true ? S : L;

  const onClick = () => {
    const route = routes?.patient?.editPatient?.replace(':id', id.toString());

    goTo(route);
  };

  const shouldRender = !isNullOrUndefined(buttonProps.id);

  return (
    <RenderIf validate={shouldRender}>
      <jello-button
        class={styles.jelloButton}
        disabled={disabled ? 'true' : null}
        onClick={onClick}
        size={size}
        variant={JELLO_BUTTON_VARIANTS.SECONDARY}
        left-icon-name={buttonProps.icon}
        {...buttonProps.testId}
      >
        {t(buttonProps.label)}
      </jello-button>
    </RenderIf>
  );
};
