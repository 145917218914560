import { connect } from 'react-redux';
import { SidebarmenuJelloComponent } from './sidebar-menu-jello.component';
import { mapDispatchers } from 'src/utils/map-dispatchers';
import { createStructuredSelector } from 'reselect';
import { push } from 'react-router-redux';
import { clearPatientDateRange } from 'src/core/patient-date-range/patient-date-range.action';
import { clearCgmDateRange } from 'src/domains/patient-dashboards/cgm/store/cgm.actions';
import {
  PATIENT_PERMISSIONS as PP,
  PERMISSIONS as P,
} from 'src/core/permissions/permissions.constants';
import {
  ADMINISTRATIVE,
  GENERAL_PRACTITIONER,
} from 'src/core/user/user.constants';

const mapStateToProps = (_, props) => {
  const { activeDevices, permissions, patientPermissions, profile } = props;

  const hasBlindedStudyPermissions = patientPermissions.includes(
    PP.BLINDED_STUDY,
  );

  const isStripMngProfessional = permissions.includes(P.STRIP_MNG_PROFESSIONAL);
  const isDeliveryProfessional = permissions.includes(P.DELIVERY_PROFESSIONAL);
  const isStripMngPatient = patientPermissions.includes(PP.STRIP_MNG_PATIENT);
  const isHomeDeliveryPatient = patientPermissions.includes(
    PP.HOME_DELIVERY_PATIENT,
  );

  const hasProfessionalStripAndDeliveryPermissions = () =>
    isStripMngProfessional || isDeliveryProfessional;
  const hasPatientOrProfessionalPermissions = () =>
    (isStripMngPatient || isHomeDeliveryPatient) &&
    hasProfessionalStripAndDeliveryPermissions();
  const isStripMngDisabled = () =>
    !hasPatientOrProfessionalPermissions() || hasBlindedStudyPermissions;

  const isCgmDisabled = () => hasBlindedStudyPermissions;
  const isPatternDisabled = () => hasBlindedStudyPermissions;

  return {
    disabled: createStructuredSelector({
      bgm: () => false,
      cgm: isCgmDisabled,
      patterns: isPatternDisabled,
      stripManagement: isStripMngDisabled,
      patientDevices: () => activeDevices.length === 0,
      editPatient: () => false,
    }),
    hidden: createStructuredSelector({
      bgm: () => profile === ADMINISTRATIVE,
      cgm: () => profile === ADMINISTRATIVE,
      patterns: () => !permissions.includes(P.ADVANCED_INDICATORS),
      stripManagement: () =>
        !(
          permissions.includes(P.STRIP_MNG_PROFESSIONAL) ||
          permissions.includes(P.DELIVERY_PROFESSIONAL)
        ) ||
        (profile === ADMINISTRATIVE &&
          patientPermissions.includes(PP.HOME_DELIVERY_PATIENT)),
      patientDevices: () =>
        profile === ADMINISTRATIVE || profile === GENERAL_PRACTITIONER,
      editPatient: () => false,
    }),
    isPatientStrip: patientPermissions.includes(PP.STRIP_MNG_PATIENT),
    isPatientHomeDelivery: patientPermissions.includes(
      PP.HOME_DELIVERY_PATIENT,
    ),
  };
};

const mapDispatchToProps = mapDispatchers({
  goTo: (path) => push(path),
  onClearBgDateRange: clearPatientDateRange,
  onClearCgmDateRange: clearCgmDateRange,
});

export const SidebarMenuComponent = connect(
  mapStateToProps,
  mapDispatchToProps,
)(SidebarmenuJelloComponent);
