import { Action, PayloadAction } from 'src/app/store/app.types';
import {
  CreatePatientWithFhirParams,
  CreatePatientWithFhirErrorResponse,
} from 'src/services/patient/create-patient-with-fhir/create-patient-with-fhir.types';

export enum CreateFhirPatientActionType {
  CREATE_FHIR_PATIENT_START = 'CREATE_FHIR_PATIENT_START',
  CREATE_FHIR_PATIENT_SUCCESS = 'CREATE_FHIR_PATIENT_SUCCESS',
  CREATE_FHIR_PATIENT_ERROR = 'CREATE_FHIR_PATIENT_ERROR',
  CLEAR_CREATE_FHIR_PATIENT_STATE = 'CLEAR_CREATE_FHIR_PATIENT_STATE',
}

export type CreateFhirPatientStartPayload = {
  fhirData: CreatePatientWithFhirParams;
  isAdmin: boolean;
};

export type CreateFhirPatientErrorPayload = CreatePatientWithFhirErrorResponse;

export type CreateFhirPatientStartAction = PayloadAction<
  CreateFhirPatientActionType.CREATE_FHIR_PATIENT_START,
  CreateFhirPatientStartPayload
>;

export type CreateFhirPatientSuccessAction =
  Action<CreateFhirPatientActionType.CREATE_FHIR_PATIENT_SUCCESS>;

export type CreateFhirPatientErrorAction = PayloadAction<
  CreateFhirPatientActionType.CREATE_FHIR_PATIENT_ERROR,
  CreateFhirPatientErrorPayload
>;

export type ClearCreateFhirPatientStateAction =
  Action<CreateFhirPatientActionType.CLEAR_CREATE_FHIR_PATIENT_STATE>;

export type CreateFhirPatientActions =
  | CreateFhirPatientStartAction
  | CreateFhirPatientSuccessAction
  | CreateFhirPatientErrorAction
  | ClearCreateFhirPatientStateAction;

export type CreateFhirPatientState = {
  isCreatingPatient: boolean;
  createPatientWasSuccessful: boolean;
  createPatientHasError: boolean;
};
