import { Epic } from 'redux-observable';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { State } from 'src/app/store/app.types';
import { selectAccessToken } from 'src/app/session/core/oidc/oidc.selectors';
import { selectGigyaToken } from 'src/app/session/core/config/config.selectors';
import { CreatePatientWithFhirLoaderImplType } from 'src/services/patient/create-patient-with-fhir/create-patient-with-fhir.types';
import { push } from 'react-router-redux';
import { addAlertAction } from 'src/core/alert/alert.actions';
import { AlertType } from 'src/core/alert/alert.types';
import { ALERT_POSITION } from 'src/core/alert/alert.constans';
import { clearPatientState } from 'src/core/patient/patient.action';
import {
  CreateFhirPatientActionType,
  CreateFhirPatientStartAction,
  CreateFhirPatientActions,
} from './create-patient.actions.types';
import {
  createFhirPatientError,
  createFhirPatientSuccess,
  clearCreateFhirPatientStateAction,
} from './create-patient.actions';
import { useConnectionCompletedWithPairingCodeCountlyEvent } from '../../hooks/create-hooks/create-countly-events-hook';

export const createFhirPatientEpic: (
  createPatientWithFhirService: CreatePatientWithFhirLoaderImplType,
) => Epic<CreateFhirPatientActions, State> =
  (checkHealthcareIdService) => (action$, store$) =>
    action$
      .ofType(CreateFhirPatientActionType.CREATE_FHIR_PATIENT_START)
      .switchMap(({ payload }: CreateFhirPatientStartAction) => {
        const accessToken = selectAccessToken(store$.getState());
        const gigyaToken = selectGigyaToken(store$.getState());
        return Observable.fromPromise(
          checkHealthcareIdService(payload.fhirData, accessToken, gigyaToken),
        )
          .flatMap(({ patientProfileUrl, patientId }) => [
            clearPatientState(),
            createFhirPatientSuccess(),
            useConnectionCompletedWithPairingCodeCountlyEvent(
              payload.fhirData.pairingCode,
            ),
            push(
              payload.isAdmin ? `/patients/${patientId}` : patientProfileUrl,
            ),
            addAlertAction({
              type: AlertType.SUCCESS,
              text: {
                [AlertType.SUCCESS]:
                  'createPatientWithPlatform.alert.creationSuccess',
              },
              position: ALERT_POSITION.BOTTOM_RIGHT,
            }),
            clearCreateFhirPatientStateAction(),
          ])
          .pipe(
            catchError((err) => Observable.of(createFhirPatientError(err))),
          );
      });
